import React, { useEffect, useRef, useState } from "react";
import { CoverSpace, FormComponents } from "./AddEquipmentStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { connect, useSelector } from "react-redux";
import { BadgeIconTop } from "./AddEditEquipmentContainer";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { uniqList } from "../../importFile/utils/helpers";
import { OwcIcon, OwcInput, OwcTypography } from "@one/react";
import CustomAutoCompleteWithMultiSelect from "../../../components/shared/CustomAutoCompleteWithMultiSelect";
import { compose, withApollo } from "react-apollo";
import { getTagsMaster } from "../loadInstrumentsInfoService";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { defaultInputStyle } from "../../../utils/helpers/text";

const NETWORK_LIST = ["CoreLab", "Molecular", "Point of Care", "Pathology", "Sequencing"];

const DEFAULT_NETWORK = {
  Molecular: NETWORK_LIST[1],
  CLP: NETWORK_LIST[0]
};

const EquipmentDescription = ({ instrumentDetail, isEditMode, client }) => {
  const [tagList, setTagList] = useState([]);
  const secondaryIdValues = instrumentDetail?.linkedInstanceRef?.secondaryIdMapping
    ? instrumentDetail?.linkedInstanceRef?.secondaryIdMapping
    : instrumentDetail?.secondaryIDMapping;
  const areThereAnySemiColon = secondaryIdValues?.includes(";");
  let disableArr = [];
  if (areThereAnySemiColon) {
    disableArr = secondaryIdValues?.split("; ");
  } else {
    disableArr.push(secondaryIdValues);
  }

  const formik = useFormikContext();
  const classes = {};

  const listOptions = useSelector((state) => state.instruments);
  const categoryMappingList = listOptions.categoryMappingMasterList;
  const [categoryList, setCategoryList] = useState(listOptions.categoryList);
  const [manufacturerList, setManufacturerList] = useState(listOptions.manufacturerList);
  const [modelList, setModelList] = useState(listOptions.modelList);
  const [variantList, setVariantList] = useState(listOptions.variantList);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [tempManufacturerList, setTempManufacturerList] = useState(listOptions.manufacturerList);
  const [tempModelList, setTempModelList] = useState(listOptions.modelList);
  const [tempVariantList, setTempVariantList] = useState(listOptions.variantList);

  const currentCategory = useRef(null);
  const currentManufacturer = useRef(null);
  const currentModel = useRef(null);

  if (!formik.values?.equipmentCategory) {
    formik.setErrors({
      ...formik.errors,
      equipmentCategory: "Category is required"
    });
  }

  const onOpenCategory = async () => {
    currentCategory.current = selectedCategory;
  };

  const onCloseCategory = async () => {
    if (formik?.values?.equipmentCategory) {
      if (currentCategory.current !== selectedCategory) {
        setManufacturerList(() => tempManufacturerList);
        setSelectedManufacturer(() => (formik?.values?.manufacturer ? formik?.values?.manufacturer : null));
        setModelList(() => tempModelList);
        setSelectedModel(() => (formik?.values?.equipmentModel ? formik?.values?.equipmentModel : null));
        setVariantList(() => tempVariantList);
        setSelectedVariant(() => (formik?.values?.variant ? formik?.values?.variant : null));
      }
    } else if (!formik?.values?.equipmentCategory) {
      setManufacturerList(() => manufacturerList);
      setSelectedManufacturer(() => null);
      setModelList(() => modelList);
      setSelectedModel(() => null);
      setVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
  };

  const onOpenManufacturer = async () => {
    currentManufacturer.current = selectedManufacturer;
  };

  const onCloseManufacturer = async () => {
    if (formik?.values?.manufacturer) {
      if (currentManufacturer.current !== selectedManufacturer) {
        setModelList(() => tempModelList);
        setSelectedModel(() => (formik?.values?.equipmentModel ? formik?.values?.equipmentModel : null));
        setVariantList(() => tempVariantList);
        setSelectedVariant(() => (formik?.values?.variant ? formik?.values?.variant : null));
      }
    } else if (!formik?.values?.manufacturer) {
      setModelList(() => modelList);
      setSelectedModel(() => null);
      setVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
  };

  const onOpenModel = async () => {
    currentModel.current = selectedModel;
  };

  const onCloseModel = async () => {
    if (formik?.values?.equipmentModel) {
      if (currentModel.current !== selectedModel) {
        setVariantList(() => tempVariantList);
        setSelectedVariant(() => (formik?.values?.variant ? formik?.values?.variant : null));
      }
    } else if (!formik?.values?.equipmentModel) {
      setModelList(() => modelList);
      setSelectedModel(() => null);
      setVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
  };

  const categoryChange = (value) => {
    formik.setValues({
      ...formik.values,
      equipmentCategory: value === null ? "" : value
    });
    setSelectedCategory(() => value);
    if (!value) {
      setManufacturerList(() => manufacturerList);
      setSelectedManufacturer(() => null);
      setModelList(() => modelList);
      setSelectedModel(() => null);
      setVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
    if (formik.values.linkedInstance?.linkedInstance !== "None") {
      const filterList = categoryMappingList.filter((item) => item.equipmentCategory === value);
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => tempMList.includes(item.equipmentModel))
          .map((x) => x.variant)
          .sort()
      );

      formik.setValues({
        ...formik.values,
        equipmentCategory: value === null ? "" : value,
        manufacturer:
          tempMFList.length === 1 ? tempMFList[0] : selectedCategory === value ? formik.values?.manufacturer : "",
        equipmentModel:
          tempMList.length === 1 && tempMFList.length === 1
            ? tempMList[0]
            : selectedCategory === value
            ? formik.values?.equipmentModel
            : "",
        variant:
          tempVList.length === 1 && tempMList.length === 1 && tempMFList.length === 1
            ? tempVList[0]
            : selectedCategory === value
            ? formik.values?.variant
            : ""
      });
      setTempManufacturerList(() => tempMFList);
      setSelectedManufacturer(() =>
        tempMFList.length === 1 ? tempMFList[0] : selectedCategory === value ? formik.values?.manufacturer : null
      );
      setTempModelList(() => tempMList);
      setSelectedModel(() =>
        tempMList.length === 1 && tempMFList.length === 1
          ? tempMList[0]
          : selectedCategory === value
          ? formik.values?.equipmentModel
          : null
      );
      setTempVariantList(() => tempVList.filter((x) => x !== ""));
      setSelectedVariant(() =>
        tempVList.length === 1 && tempMList.length === 1 && tempMFList.length === 1
          ? tempVList[0]
          : selectedCategory === value
          ? formik.values?.variant
          : null
      );
    } else {
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel)
          .map((x) => x.variant)
          .sort()
      );

      formik.setValues({
        ...formik.values,
        equipmentCategory: value === null ? "" : value,
        variant: tempVList.length === 1 ? tempVList[0] : selectedCategory === value ? formik.values?.variant : ""
      });

      const filterList = categoryMappingList.filter((item) => item.equipmentCategory === value);
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();

      setTempManufacturerList(() => tempMFList);
      setTempModelList(() => tempMList);
      setTempVariantList(() => tempVList.filter((x) => x !== ""));
      setSelectedVariant(() =>
        tempVList.length === 1 ? tempVList[0] : selectedCategory === value ? formik.values?.variant : null
      );
    }
  };
  const manufacturerChange = (value) => {
    const filterList = categoryMappingList.filter(
      (item) => item.manufacturer === value && item.equipmentCategory === formik.values?.equipmentCategory
    );

    const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();
    const tempVList = uniqList(
      categoryMappingList.filter((item) => tempMList.includes(item.equipmentModel)).map((x) => x.variant)
    ).sort();

    formik.setValues({
      ...formik.values,
      manufacturer: value === null ? "" : value,
      equipmentModel:
        tempMList.length === 1 ? tempMList[0] : selectedManufacturer === value ? formik.values?.equipmentModel : "",
      variant:
        tempVList.length === 1 && tempMList.length === 1
          ? tempVList[0]
          : selectedManufacturer === value
          ? formik.values?.variant
          : ""
    });
    setSelectedManufacturer(() => value);
    setTempModelList(() => tempMList);
    setSelectedModel(() =>
      tempMList.length === 1 ? tempMList[0] : selectedManufacturer === value ? formik.values?.equipmentModel : null
    );
    setTempVariantList(() => tempVList.filter((x) => x !== ""));
    setSelectedVariant(() =>
      tempVList.length === 1 && tempMList.length === 1
        ? tempVList[0]
        : selectedManufacturer === value
        ? formik.values?.variant
        : null
    );
    if (!value) {
      setTempModelList(modelList);
      setSelectedModel(() => null);
      setTempVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
  };

  const modelChange = (value) => {
    const filterList = categoryMappingList.filter((item) => item.equipmentModel === value);
    const tempVList = uniqList(filterList.map((x) => x.variant)).sort();
    formik.setValues({
      ...formik.values,
      equipmentModel: value === null ? "" : value,
      variant: tempVList.length === 1 ? tempVList[0] : selectedModel === value ? formik.values?.variant : ""
    });
    setSelectedModel(() => value);
    setTempVariantList(() => tempVList.filter((x) => x !== ""));
    setSelectedVariant(() =>
      tempVList.length === 1 ? tempVList[0] : selectedModel === value ? formik.values?.variant : null
    );
    if (!value) {
      setVariantList(() => variantList);
      setSelectedVariant(() => null);
    }
    if (!formik.values?.manufacturer) {
      const CMFilterList = categoryMappingList.filter(
        (item) => item.equipmentModel === value && item.equipmentCategory === formik.values?.equipmentCategory
      );

      const tempMFList = uniqList(CMFilterList.map((x) => x.manufacturer)).sort();

      const tempMList = uniqList(
        categoryMappingList.filter((item) => tempMFList.includes(item.manufacturer)).map((x) => x.equipmentModel)
      ).sort();

      setTempModelList(() => tempMList);
      setTempManufacturerList(() => tempMFList);
      if (tempMFList.length === 1) {
        formik.setValues({
          ...formik.values,
          equipmentModel: value === null ? "" : value,
          variant: tempVList.length === 1 ? tempVList[0] : selectedModel === value ? formik.values?.variant : "",
          manufacturer: tempMFList[0]
        });
        setSelectedManufacturer(() => tempMFList[0]);
        setSelectedVariant(() =>
          tempVList.length === 1 ? tempVList[0] : selectedModel === value ? formik.values?.variant : null
        );
      }
    }
  };
  const variantChange = (value) => {
    formik.setValues({
      ...formik.values,
      variant: value === null ? "" : value
    });
    setSelectedVariant(value);
    if (!formik.values?.equipmentModel && formik.values.linkedInstance?.linkedInstance !== "None") {
      const tempList = uniqList(
        categoryMappingList.filter((item) => item.variant === value).map((item) => item.equipmentModel)
      );
      const filterList = categoryMappingList.filter((item) => tempList.includes(item.equipmentModel));

      const tempCList = uniqList(filterList.map((x) => x.equipmentCategory)).sort();
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();

      formik.setValues({
        ...formik.values,
        variant: value === null ? "" : value,
        equipmentCategory: tempCList.length === 1 ? tempCList[0] : formik.values?.equipmentCategory,
        manufacturer: tempMFList.length === 1 ? tempMFList[0] : formik.values?.manufacturer,
        equipmentModel: tempMList.length === 1 ? tempMList[0] : formik.values?.equipmentModel
      });
      setSelectedCategory(() => (tempCList.length === 1 ? tempCList[0] : formik.values?.equipmentCategory));
      setSelectedManufacturer(() => (tempMFList.length === 1 ? tempMFList[0] : formik.values?.manufacturer));
      setSelectedModel(() => (tempMList.length === 1 ? tempMList[0] : formik.values?.equipmentModel));
    }
  };

  useEffect(() => {
    const tempList = categoryMappingList.filter((item) => {
      return (
        item.equipmentModel === formik.values.equipmentModel &&
        item.equipmentCategory === formik.values.equipmentCategory &&
        item.manufacturer === formik.values.manufacturer
      );
    });
    if (tempList.length === 1) {
      formik.values.displayImage = tempList[0].displayImage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.equipmentModel]);
  useEffect(() => {
    if (!isEditMode) {
      const filterList = categoryMappingList.filter(
        (item) => item.equipmentCategory === formik.values?.equipmentCategory
      );
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(
        filterList.filter((item) => item.manufacturer === formik.values?.manufacturer).map((x) => x.equipmentModel)
      ).sort();
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel)
          .map((x) => x.variant)
          .sort()
      );
      setSelectedCategory(() => (formik.values?.equipmentCategory ? formik.values?.equipmentCategory : null));
      setManufacturerList(tempMFList);
      setSelectedManufacturer(() => (formik.values?.manufacturer ? formik.values?.manufacturer : null));
      setModelList(tempMList);
      setSelectedModel(() => (formik.values?.equipmentModel ? formik.values?.equipmentModel : null));
      setVariantList(tempVList.filter((x) => x !== ""));
      setSelectedVariant(() => (formik.values?.variant ? formik.values?.variant : null));
      formik.setFieldTouched("equipmentNickName");
    } else {
      const filterList = categoryMappingList.filter(
        (item) => item.equipmentCategory === formik.values?.equipmentCategory
      );
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(
        filterList.filter((item) => item.manufacturer === formik.values?.manufacturer).map((x) => x.equipmentModel)
      ).sort();
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel)
          .map((x) => x.variant)
          .sort()
      );
      setSelectedCategory(() => `${formik.values?.equipmentCategory}`);
      setManufacturerList(tempMFList);
      setSelectedManufacturer(() => `${formik.values?.manufacturer}`);
      setModelList(tempMList);
      setSelectedModel(() => `${formik.values?.equipmentModel}`);
      setVariantList(tempVList.filter((x) => x !== ""));
      setSelectedVariant(() => `${formik.values?.variant}`);
    }
    getTagsMasterDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.linkedInstance?.linkedInstance === "None") {
      const filterList = categoryMappingList.filter(
        (item) =>
          item.manufacturer === formik.values?.manufacturer && item.equipmentModel === formik.values?.equipmentModel
      );

      const tempCList = uniqList(filterList.map((x) => x.equipmentCategory)).sort();
      const tempMFList = uniqList(filterList.map((x) => x.manufacturer)).sort();
      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel)
          .map((x) => x.variant)
          .sort()
      );

      setCategoryList(() => tempCList);
      if (!formik.values?.equipmentCategory) {
        formik.setFieldValue("equipmentCategory", "", false);
        setSelectedCategory(() => null);
        if (tempCList.length === 1) {
          formik.setFieldValue("equipmentCategory", tempCList[0]);
          setSelectedCategory(() => tempCList[0]);
          setSelectedManufacturer(() => formik.values?.manufacturer);
        }
      }
      setManufacturerList(() => tempMFList);
      if (formik.values?.manufacturer) {
        setSelectedManufacturer(() => formik.values?.manufacturer);
      }
      setModelList(() => tempMList);
      if (formik.values?.equipmentModel) {
        setSelectedModel(() => formik.values?.equipmentModel);
      }
      setVariantList(() => tempVList.filter((x) => x !== ""));
      if (!formik.values?.variant) {
        formik.setFieldValue("variant", "", false);
        setSelectedVariant(() => null);
        if (tempVList.length === 1 && tempCList.length === 1) {
          formik.setFieldValue("variant", tempVList[0], false);
          setSelectedVariant(() => tempVList[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.linkedInstance?.linkedInstance]);

  const isDisabled = (fieldName) => {
    return disableArr.includes(fieldName);
  };
  const getTagsMasterDatas = async () => {
    const tagsData = await getTagsMaster(client);

    if (tagsData?.items.length > 0) {
      setTagList(uniqList(tagsData.items.map((x) => x.filterValue)));
    }
  };

  const handleDelete = (selectedObjects, selectedObject, setValue) => {
    selectedObjects = selectedObjects.filter((currentObject) => currentObject !== selectedObject);
    setValue(selectedObjects);
  };

  // useEffect assigns default value to network if not present
  useEffect(() => {
    if (formik.values.network === undefined && !isEditMode) {
      const { linkedInstanceDisplay } = formik.values.linkedInstance;
      const defaultNetworkValue = DEFAULT_NETWORK[linkedInstanceDisplay];
      formik.setValues({
        ...formik.values,
        network: defaultNetworkValue
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.linkedInstance]);

  return (
    <>
      <CoverSpace>
        <OwcTypography variant="caption">SAP Mapped Fields</OwcTypography>
        <BadgeIconTop></BadgeIconTop>
      </CoverSpace>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Equipment description
        </OwcTypography>
      )}
      <FormComponents>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.equipmentCategory.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.equipmentCategory.value + ` *`}
            keylabel={DATA_MODEL_TABLE.equipmentCategory.key}
            style={defaultInputStyle}
            list={categoryList}
            selected={selectedCategory}
            onChange={(selectedValue) => {
              const selVal = categoryList?.find((value, index) => index === selectedValue);
              categoryChange(selVal ?? null);
            }}
            onOpen={onOpenCategory}
            onClose={onCloseCategory}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.equipmentCategory.key]}
          />
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.manufacturer.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.manufacturer.value + ` *`}
            keylabel={DATA_MODEL_TABLE.manufacturer.key}
            style={defaultInputStyle}
            list={manufacturerList}
            selected={selectedManufacturer}
            onChange={(selectedValue) => {
              const selVal = manufacturerList?.find((value, index) => index === selectedValue);
              manufacturerChange(selVal ?? null);
            }}
            onOpen={onOpenManufacturer}
            onClose={onCloseManufacturer}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.manufacturer.key]}
            disabled={
              instrumentDetail?.linkedInstanceRef?.linkedInstance !== "None" &&
              isDisabled(DATA_MODEL_TABLE.manufacturer.key)
            }
          />
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.equipmentModel.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.equipmentModel.value + ` *`}
            keylabel={DATA_MODEL_TABLE.equipmentModel.key}
            style={defaultInputStyle}
            list={modelList}
            selected={selectedModel}
            onChange={(selectedValue) => {
              const selVal = modelList?.find((value, index) => index === selectedValue);
              modelChange(selVal ?? null);
            }}
            onOpen={onOpenModel}
            onClose={onCloseModel}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.equipmentModel.key]}
            disabled={
              instrumentDetail?.linkedInstanceRef?.linkedInstance !== "None" &&
              isDisabled(DATA_MODEL_TABLE.equipmentModel.key)
            }
          />
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.variant.value}
            defaultLabel={DATA_MODEL_TABLE.variant.value}
            keylabel={DATA_MODEL_TABLE.variant.key}
            style={defaultInputStyle}
            list={variantList}
            selected={selectedVariant}
            disabled={!formik.values?.equipmentModel || !variantList.length}
            onChange={(selectedValue) => {
              const selVal = variantList?.find((value, index) => index === selectedValue);
              variantChange(selVal ?? null);
            }}
          />
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-cluster`}
              label={DATA_MODEL_TABLE.cluster.value}
              name="cluster"
              style={defaultInputStyle}
              value={formik.values?.cluster}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue(classes, formik, "cluster", "cluster", "text-field")}
            >
              {formik.values[DATA_MODEL_TABLE.cluster.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.cluster.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.cluster.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-equipment-nickname`}
              label={DATA_MODEL_TABLE.equipmentNickName.value}
              name="equipmentNickName"
              style={defaultInputStyle}
              value={formik.values?.equipmentNickName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue(
                classes,
                formik,
                "equipmentNickName",
                "equipmentNickName",
                "text-field"
              )}
            >
              {formik.values[DATA_MODEL_TABLE.equipmentNickName.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.equipmentNickName.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.equipmentNickName.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owctagInputBox">
            <CustomAutoCompleteWithMultiSelect
              style={{
                width: "635px"
              }}
              id={DATA_MODEL_TABLE.tags.key}
              dataTestid={DATA_MODEL_TABLE.tags.key}
              optionsList={tagList ?? []}
              label={DATA_MODEL_TABLE.tags.value}
              removable={true}
              handleDelete={handleDelete}
              value={formik.values?.tags ?? []}
              disableCloseOnSelect={true}
              setValue={(newValue) => {
                formik.setFieldValue("tags", newValue, true);
              }}
              fullWidth={true}
              name={DATA_MODEL_TABLE.tags.key}
              multiple={true}
              error={formik.touched[DATA_MODEL_TABLE.tags.key] && Boolean(formik.errors[DATA_MODEL_TABLE.tags.key])}
              helperText={
                (formik.touched[DATA_MODEL_TABLE.tags.key] && formik.errors[DATA_MODEL_TABLE.tags.key]) ??
                "You can add/select max of 10 tags and each tag of 20 characters max"
              }
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="owcInputBox">
            <OwcInput
              style={{
                width: "320px"
                // height: "122px"
              }}
              formik={formik}
              data-testid={`textarea-field-comment`}
              label={DATA_MODEL_TABLE.comment.value}
              name="comment"
              value={formik.values?.comment}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue(classes, formik, "comment", "comment", "text-field")}
              multiline
              rows={6}
            >
              {formik.values[DATA_MODEL_TABLE.comment.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.comment.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.comment.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.network.value}
            defaultLabel={DATA_MODEL_TABLE.network.value}
            keylabel={DATA_MODEL_TABLE.network.key}
            style={defaultInputStyle}
            list={NETWORK_LIST}
            selected={formik.values?.network}
            onChange={(selectedValue) => {
              formik.setValues({
                ...formik.values,
                network: NETWORK_LIST[selectedValue] ?? null
              });
            }}
            helperText={formik.errors[DATA_MODEL_TABLE.network.key]}
          />
        </>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default compose(connect(mapStateToProps), withApollo)(EquipmentDescription);
