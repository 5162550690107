import React from "react";
import { ConfirmDialog } from "../../components/shared";
import { CreateFieldStyled } from "./GroupMainPageStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import CustomSearchText from "../../components/shared/CustomSearchText";
import { OwcButton, OwcInput, OwcIcon } from "@one/react";

const GroupDialog = ({ open, isEditMode, onCancel, submit, userList, loading }) => {
  const formik = useFormikContext();
  const classes = {};
  const isEdit = isEditMode;
  return (
    <ConfirmDialog
      open={open}
      close={onCancel}
      approveText={isEditMode ? "Update" : "Create"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      isDivider
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit group" : "Create group"}
      renderActions={({ cancelColor, cancelVariant, approveColor, approveVariant, cancelText, approveText }) => (
        <div style={{ display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              submit(formik.values);
            }}
            variant="primary"
            disabled={!(formik.isValid && formik.dirty) || formik.values?.groupName?.trim() === "" || loading}
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>
        <OwcInput
          data-testid={`text-field-group-name`}
          label="Enter group name *"
          className="TextFieldInput"
          style={{ marginRight: "20px" }}
          name="groupName"
          value={formik.values?.groupName}
          onInputChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isEditMode}
          required
          {...commonPropsForInputsWithoutValue(classes, formik, "groupName", "groupName", "text-field", isEdit)}
        >
          {formik.errors["groupName"] && (
            <>
              <span
                // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                slot="error-text"
              >
                {formik.errors["groupName"] !== "Group name is required"
                  ? formik.errors["groupName"]
                  : !formik.values?.groupName && formik.touched.groupName === true
                  ? formik.errors["groupName"]
                  : ""}
              </span>
            </>
          )}
          {formik.values["groupName"] !== "" && (
            <OwcIcon
              // id={generateID.UUID(
              //   adminTopBannerMessage,
              //   `topBannerMessage`,
              //   "icon_clear_circle"
              // )}
              name="circle_clear_filled"
              slot="suffix"
              type="legacy"
              onClick={() => formik.setFieldValue("groupName", "", true)}
            />
          )}
        </OwcInput>
        <CustomSearchText
          style={{ width: "300px" }}
          id="userIds"
          dataTestid={`userIds-inputs-userIds-input`}
          optionsList={userList}
          propValue="key"
          propLabel="value"
          label="Select user"
          value={formik.values?.userIds || []}
          setValue={(newValue) => {
            formik.setFieldValue("userIds", newValue, true);
          }}
          name="userIds"
          fullWidth={true}
          error={formik.touched["userIds"] && Boolean(formik.errors["userIds"])}
          helperText={formik.touched["userIds"] && formik.errors["userIds"]}
          multiple={true}
          onBlur={formik.handleBlur}
          required={true}
        />
      </CreateFieldStyled>
    </ConfirmDialog>
  );
};

export default GroupDialog;
