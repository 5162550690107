import React from "react";
import { OwcEditableDropdown, OwcListItem } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { SOP_MANDATORY_CLP } from "../../../../constants";

const CommonOwcEditableDropDown = ({
  labelValue,
  style,
  list,
  selected = null,
  label,
  propValue,
  defaultLabel,
  onChange,
  helperText,
  onClose,
  onOpen,
  required = false,
  disabled = false,
  sopHelperText = false
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: sopHelperText ? "8px 8px 0px 0px" : "8px 8px 8px 0px"
        }}
      >
        <OwcEditableDropdown
          style={style}
          label={labelValue ? label : selected !== null ? defaultLabel : label}
          placeholder={labelValue ? "" : label}
          value={selected}
          onSelectChange={(e) => {
            onChange(e.detail);
          }}
          required={required}
          onPanelOpen={onOpen}
          disabled={disabled}
          onPanelClose={onClose}
          requiredText={helperText}
        >
          {list?.map((item) => {
            const option = item?.[propValue] || item;
            return (
              <OwcListItem
                value={option}
                key={option}
                data-testid={`${label}-${option}`}
                id={generateID.UUID(label, `EditableDropDown_${label}-${option}`, "item")}
              >
                {option}
              </OwcListItem>
            );
          })}
        </OwcEditableDropdown>
      </div>
      {sopHelperText && (
        <div
          style={{
            display: "flex",
            fontSize: "var(--one-text-font-size-rem-0-75)",
            pointerEvents: "none",
            padding: "0px 16px 0px 16px",
            color: "var(--one-color-interaction-default-neutral-3)"
          }}
        >
          {SOP_MANDATORY_CLP}
        </div>
      )}
    </div>
  );
};

export default CommonOwcEditableDropDown;
