import React, { useEffect, useState } from "react";
import { isEqual, pick } from "underscore";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { API, Auth } from "aws-amplify";
import {
  loadUserInfo as loadUserInfoAction,
  loadActionStatus as loadActionStatusAction,
  loadLastFilter,
  updateLastDisplayColumn,
  updateUserDisplayColumns as updateUserDisplayColumnsAction,
  updateSystemDisplayColumn as updateSystemDisplayColumnAction
} from "../user/redux/actions";
import {
  updateInstrumentFilter as updateInstrumentFilterAction,
  loadStepFormList as loadStepFormListAction,
  updateBuildingOnSite as updateBuildingOnSiteAction,
  updateFloorOnSite as updateFloorOnSiteAction,
  updateRoomOnSite as updateRoomOnSiteAction,
  loadBuildingData as loadBuildingDataAction,
  loadFloorData as loadFloorDataAction,
  loadRoomData as loadRoomDataAction,
  loadReasonData as loadReasonDataAction
} from "./redux/actions";
import styled from "styled-components";
import { signOut } from "../../utils/signout";
import { getSortedUniqueList, sortedList } from "../importFile/utils/helpers";
import {
  INSTRUMENT_REPOSITORY_ADMIN,
  GROUPS_TOKEN_PATH,
  DEFAULT_SITE_NAME,
  DEFAULT_SITE_TIMEZONE,
  ACCESS_TOKEN_PATH,
  SYNC_PROFILE_FIELDS,
  INSTRUMENT_REPOSITORY_USER,
  INSTRUMENT_REPOSITORY_VIEWER,
  allTableColumn,
  DEFAULT_FILTER,
  DEFAULT_IR_PAGE_SIZE,
  SEARCH_PREFERENCE_LIST,
  IR_FILE_UPLOAD_STATUS,
  DEFAULT_MYLAB_FILTER,
  MY_LAB,
  DEFAULT_COLUMNS
} from "../../constants";
import {
  LIST_SITES,
  GET_LOGGED_USER_DETAILS,
  LIST_DIGITAL_LAB_INSTRUMENT_USER_FILTER,
  LIST_IR_BUILDING_MASTER,
  LIST_IR_FLOOR_MASTER,
  LIST_IR_ROOM_MASTER,
  lIST_IR_GROUP_ADMIN_MAPPINGS,
  LIST_IR_DISPLAY_COLUMNS,
  LIST_IR_SYSTEM_DISPLAY_COLUMNS,
  GET_IR_BUILDING,
  GET_IR_FLOOR,
  GET_IR_ROOM,
  LIST_IR_INBOXES,
  LIST_IR_UPLOAD_STATUS_MASTER,
  LP_GET_LOGGED_USER_DETAILS_USER_FILTERS,
  LIST_IR_EDITREASON_MASTER
} from "../../gql/landingapi";
import { getAllData, getData } from "../../utils/helpers/fetching";
import {
  CREATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE,
  UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS
} from "../../gql/landingapi";
import { ON_CREATE_IR_INBOX, ON_UPDATE_IR_INBOX, ON_UPDATE_IR_UPLOAD_STATUS } from "../../gql/landingapi/subscription";
import Notify from "../notifications/Notify";
import omitDeep from "omit-deep-lodash";
import { find, get, sortBy } from "lodash";
import GenericSubscription from "../../components/shared/GenericSubscription";
import { useHistory } from "react-router-dom";
import { checkRoleInstrumentRepo } from "../../utils/helpers/checkRoleInstrumentRepo";
import { configureAwsAppSync } from "../../utils/helpers/configureAwsAppSync";
import { CircularProgress, Typography } from "@mui/material";
import { OwcButton } from "@one/react";
import { useParams } from "react-router-dom";
import {
  clusterCategoryMappings,
  equipmentCategoryMappings,
  getEquipmentAdministratorMaster,
  getGroupMaster,
  getLinkedInstance,
  getResponsibleMaster,
  getSops,
  getTagsMaster
} from "./loadInstrumentsInfoService";
import { isValidJson } from "../../utils/helpers/text";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;, ...syncFields
`;

class SiteError extends Error {
  constructor(message) {
    super(message);
    this.name = "SiteError";
  }
}

const checkRoleAndGetUserEmail = async (
  required_admin_group = INSTRUMENT_REPOSITORY_ADMIN,
  required_view_group = INSTRUMENT_REPOSITORY_VIEWER,
  required_user_group = INSTRUMENT_REPOSITORY_USER
) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (Array.isArray(access_groups)) {
    if (
      access_groups.includes(required_admin_group) ||
      access_groups.includes(required_view_group) ||
      access_groups.includes(required_user_group)
    ) {
      const syncFields = pick(get(currentAuthenticatedUser, ACCESS_TOKEN_PATH), Object.values(SYNC_PROFILE_FIELDS));
      const email = get(currentAuthenticatedUser, ["attributes", "email"]);
      return { email, ...syncFields };
    } else {
      throw new Error("You do not have permission to access.");
    }
  } else {
    throw new Error("You do not have permission to access.");
  }
};

export const updateProfileFields = async ({ client, email, user, ...syncFields }) => {
  const syncKeys = Object.keys(SYNC_PROFILE_FIELDS);
  const profileObj = syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFields?.[SYNC_PROFILE_FIELDS?.[currKey]] ?? ""
    }),
    {}
  );
  if (!user) {
    return profileObj;
  }
  const shouldUpdate = !isEqual(pick(user, syncKeys), {
    ...profileObj,
    site: profileObj?.site || user.site
  });
  if (!shouldUpdate) return user;
  const jsonObj =
    typeof user?.lastFilter === "string"
      ? isValidJson(user?.lastFilter)
        ? JSON.parse(user?.lastFilter)
        : { lastFavFilter: DEFAULT_FILTER }
      : user?.lastFilter;
  const {
    data: { updateDigitalLabInstrumentRepositoryUserProfile: updatedUser }
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      email,
      lastFilter: JSON.stringify(jsonObj),
      ...profileObj,
      site: profileObj?.site || user?.site
    }
  });
  return updatedUser;
};

const converLastFavObjStrToArr = (value) => {
  if (!value) {
    return null;
  }
  return typeof value === "string" ? [value] : value;
};

const convertLastFavDateFromDONM = (filterDefinition, type) => {
  if (filterDefinition?.dateOfNextMaintanance === undefined) {
    return filterDefinition[type] || null;
  } else if (
    !filterDefinition[type] &&
    filterDefinition?.dateOfNextMaintanance &&
    Array.isArray(filterDefinition?.dateOfNextMaintanance)
  ) {
    if (type === "dateTo") {
      return filterDefinition?.dateOfNextMaintanance[1] || null;
    }
    return filterDefinition?.dateOfNextMaintanance[0] || null;
  } else if (!filterDefinition[type] && filterDefinition?.dateOfNextMaintanance) {
    if (type === "dateFrom") {
      return filterDefinition?.dateOfNextMaintanance[type] || null;
    }
    return filterDefinition?.dateOfNextMaintanance[type] || null;
  }

  return null;
};

const convertLastFavDateToObj = (filterDefinition) => {
  let dateObj = {
    dateFrom: null,
    dateTo: null,
    filterType: "date",
    type: "inRange"
  };

  if (filterDefinition?.dateOfNextMaintanance === undefined) {
    dateObj = {
      ...dateObj,
      dateFrom: filterDefinition?.dateFrom ? filterDefinition?.dateFrom + " 00:00:01" : null,
      dateTo: filterDefinition?.dateTo ? filterDefinition?.dateTo + " 00:00:01" : null
    };
    return dateObj;
  } else if (filterDefinition?.dateOfNextMaintanance && Array.isArray(filterDefinition?.dateOfNextMaintanance)) {
    let fromDate = null;
    let toDate = null;
    if (filterDefinition?.dateOfNextMaintanance[0].search(":") !== -1) {
      fromDate = filterDefinition?.dateOfNextMaintanance[0];
    } else {
      fromDate = filterDefinition?.dateOfNextMaintanance[0] + " 00:00:01";
    }

    if (filterDefinition?.dateOfNextMaintanance[1] && filterDefinition?.dateOfNextMaintanance[1].search(":") !== -1) {
      toDate = filterDefinition?.dateOfNextMaintanance[1];
    } else {
      toDate = filterDefinition?.dateOfNextMaintanance[1]
        ? filterDefinition?.dateOfNextMaintanance[1] + " 00:00:01"
        : null;
    }

    if (fromDate) {
      dateObj = {
        ...dateObj,
        dateFrom: fromDate,
        dateTo: toDate
      };
    } else {
      return null;
    }
    return dateObj;
  }

  return filterDefinition?.dateOfNextMaintanance;
};

export const getLPUserDetailsUserFilter = async ({ client }) => {
  const LP_config = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT
  };

  configureAwsAppSync(LP_config);
  const userInfo = await API.graphql({
    query: LP_GET_LOGGED_USER_DETAILS_USER_FILTERS,
    client
  });

  const resultObj = get(userInfo, "data.getLoggedInUserDetails.items[0]");
  const myLabFilter = find(resultObj?.userFilters?.items, {
    filterName: MY_LAB
  });

  const myLabFilterFilterdefinition =
    typeof myLabFilter?.filterDefinition === "string"
      ? JSON.parse(myLabFilter?.filterDefinition)
      : myLabFilter?.filterDefinition;

  if (myLabFilter && myLabFilterFilterdefinition?.inventoryIds?.length > 0) {
    return {
      myLabFilter: {
        ...DEFAULT_MYLAB_FILTER,
        filterDefinition: {
          ...DEFAULT_MYLAB_FILTER?.filterDefinition,
          inventoryIds: myLabFilterFilterdefinition?.inventoryIds
        }
      }
    };
  }

  return { myLabFilter: null };
};

export const getUserDetails = async ({ client, email, updateInstrumentFilter, loadfilter, ...syncFields }) => {
  const result = await client.query({
    query: GET_LOGGED_USER_DETAILS,
    fetchPolicy: "no-cache"
  });
  let user = get(result, "data.getLoggedInUserDetails.items[0]");

  if (!syncFields?.[SYNC_PROFILE_FIELDS.site] && !user?.site) {
    throw new SiteError("You do not have site location assigned. Go to the Landing Page to configure this.");
  }
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site]) {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Your location has been changed. Please check your settings at the Landing Page."
    });
  }

  if (user && user.lastFilter) {
    try {
      user.lastFilter =
        typeof user?.lastFilter === "string"
          ? isValidJson(user?.lastFilter)
            ? JSON.parse(user?.lastFilter)
            : { lastFavFilter: DEFAULT_FILTER }
          : user?.lastFilter;
      user.lastFilter = {
        lastFavFilter: {
          ...user.lastFilter?.lastFavFilter,
          filterDefinition: {
            ...user.lastFilter?.lastFavFilter?.filterDefinition,
            site: converLastFavObjStrToArr(user.lastFilter?.lastFavFilter?.filterDefinition?.site),
            group: converLastFavObjStrToArr(user.lastFilter?.lastFavFilter?.filterDefinition?.group),
            manufacturer: converLastFavObjStrToArr(user.lastFilter?.lastFavFilter?.filterDefinition?.manufacturer),
            category: converLastFavObjStrToArr(user.lastFilter?.lastFavFilter?.filterDefinition?.category),
            responsiblePerson: converLastFavObjStrToArr(
              user.lastFilter?.lastFavFilter?.filterDefinition?.responsiblePerson
            ),
            equipmentAdministrator: converLastFavObjStrToArr(
              user.lastFilter?.lastFavFilter?.filterDefinition?.equipmentAdministrator
            ),
            inventoryIds:
              user.lastFilter?.lastFavFilter === MY_LAB
                ? converLastFavObjStrToArr(user.lastFilter?.lastFavFilter?.filterDefinition?.inventoryIds || null)
                : null,
            dateFrom: convertLastFavDateFromDONM(user.lastFilter?.lastFavFilter?.filterDefinition, "dateFrom"),
            dateTo: convertLastFavDateFromDONM(user.lastFilter?.lastFavFilter?.filterDefinition, "dateTo"),
            dateOfNextMaintanance: convertLastFavDateToObj(user.lastFilter?.lastFavFilter?.filterDefinition)
          }
        }
      };
      const dateFrom = user.lastFilter?.lastFavFilter?.filterDefinition?.dateFrom || null;
      const dateTo = user.lastFilter?.lastFavFilter?.filterDefinition?.dateTo || null;
      user.lastFilter.lastFavFilter.filterName =
        user.lastFilter?.lastFavFilter?.filterName === "Predefined filters" ||
        user.lastFilter?.lastFavFilter?.filterName === "None"
          ? DEFAULT_FILTER?.filterName
          : user.lastFilter?.lastFavFilter?.filterName;
      if (!dateFrom) {
        user.lastFilter.lastFavFilter.filterDefinition.dateFrom = DEFAULT_FILTER?.filterDefinition?.dateFrom;
      }
      if (!dateTo) {
        user.lastFilter.lastFavFilter.filterDefinition.dateTo = DEFAULT_FILTER?.filterDefinition?.dateTo;
      }
      user.lastPageSize = JSON.parse(user.lastPageSize);
      if (typeof user.lastPageSize === "string") {
        user.lastPageSize = JSON.parse(user.lastPageSize);
      }
    } catch (error) {}
  }

  if (user?.lastDisplayColumns) {
    try {
      user.lastDisplayColumns = JSON.parse(user.lastDisplayColumns);
    } catch (error) {
      console.error(error);
    }
  }

  if (user?.searchPreference) {
    try {
      const jsonSearchPrefrenceObj = JSON.parse(user.searchPreference);
      user.searchPreference = sortBy(jsonSearchPrefrenceObj, "title");
      if (user?.searchPreference?.map((item) => item.title)?.includes("Description")) {
        user.searchPreference = SEARCH_PREFERENCE_LIST;
      }
    } catch (error) {}
  } else {
    if (user !== undefined) user.searchPreference = SEARCH_PREFERENCE_LIST;
  }

  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    user,
    email,
    ...syncFields
  });
  if (profileFieldsOrUpdatedUser?.id) {
    return { user: profileFieldsOrUpdatedUser };
  }
  const {
    data: { createDigitalLabInstrumentRepositoryUserProfile }
  } = await client.mutate({
    mutation: CREATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      email,
      lastFilter: "",
      ...profileFieldsOrUpdatedUser
    }
  });

  return {
    user: createDigitalLabInstrumentRepositoryUserProfile
  };
};

export const getFilterConstData = async (data, id) => {
  let filterArr = [];
  data.forEach((item) => {
    var result = allTableColumn().find((obj) => {
      return obj.key === item.key;
    });
    result = { ...result };
    result.order = item.order;
    result.show = id === "show";
    filterArr.push(result);
  });
  return filterArr;
};

const getUserFilter = async ({ client, email, query_user_filter }) => {
  const userFilterData = await getAllData({
    query: query_user_filter,
    client,
    dataPath: ["data", "getLoggedInUserDetails"],
    drillData: true
  });

  const userFilters = userFilterData?.items[0]?.userFilters?.items || [];

  userFilters.forEach((item, index, items) => {
    items[index]["filterName"] = item?.filterName === "None" ? "" : item?.filterName;
    if (typeof item["filterDefinition"] === "string") {
      const parsedObj = JSON.parse(item["filterDefinition"]);
      items[index]["filterDefinition"] = {
        ...parsedObj,
        site: converLastFavObjStrToArr(parsedObj.site),
        group: converLastFavObjStrToArr(parsedObj.group),
        manufacturer: converLastFavObjStrToArr(parsedObj.manufacturer),
        category: converLastFavObjStrToArr(parsedObj.category),
        responsiblePerson: converLastFavObjStrToArr(parsedObj?.responsiblePerson),
        equipmentAdministrator: converLastFavObjStrToArr(parsedObj?.equipmentAdministrator),
        dateFrom: convertLastFavDateFromDONM(parsedObj, "dateFrom"),
        dateTo: convertLastFavDateFromDONM(parsedObj, "dateTo"),
        dateOfNextMaintanance: convertLastFavDateToObj(parsedObj),
        inventoryIds: null
      };
    }
  });
  return {
    userFilters
  };
};

export const updateBuilding = async (client, siteId) => {
  const buildings = await getBuildingData(client, siteId);
  return { buildings: buildings?.items || [] };
};

export const updateFloor = async (client, siteId, id) => {
  const floors = await getFloorData(client, siteId, id);
  return { floors: floors?.items || [] };
};

export const updateRoomData = async (client, siteId, id) => {
  const rooms = await getRoomData(client, siteId, id);
  return { rooms: rooms?.items || [] };
};

export const getBuildingData = async (client, siteId) => {
  return await getAllData({
    client,
    query: LIST_IR_BUILDING_MASTER,
    dataPath: ["data", "listBuildingBySiteIdAndBuildingNumber"],
    variables: { limit: 10000, siteId },
    drillData: true
  });
};
export const getFloorData = async (client, siteId, id) => {
  return await getAllData({
    client,
    query: LIST_IR_FLOOR_MASTER,
    dataPath: ["data", "listFloorBySiteIdAndFloorCode"],
    variables: { limit: 10000, siteId, id },
    drillData: true
  });
};
export const getRoomData = async (client, siteId, id) => {
  return await getAllData({
    client,
    query: LIST_IR_ROOM_MASTER,
    dataPath: ["data", "listRoomBySiteIdAndRoomCode"],
    variables: { limit: 50000, siteId, id },
    drillData: true
  });
};

export const getBuilding = async (client, id) => {
  if (isNaN(parseInt(id))) {
    return null;
  }
  const result = await getData({
    client,
    query: GET_IR_BUILDING,
    variables: { id: parseInt(id) },
    dataPath: ["data", "getIRBuildingMaster"]
  });
  return result;
};

export const getFloor = async (client, id) => {
  if (isNaN(parseInt(id))) {
    return null;
  }
  const result = await getData({
    client,
    query: GET_IR_FLOOR,
    variables: { id: parseInt(id) },
    dataPath: ["data", "getIRFloorMaster"]
  });
  return result;
};

export const getRoom = async (client, id) => {
  if (isNaN(parseInt(id))) {
    return null;
  }
  const result = await getData({
    client,
    query: GET_IR_ROOM,
    variables: { id: parseInt(id) },
    dataPath: ["data", "getIRRoomMaster"]
  });

  return result;
};

export const getReasons = async (client) => {
  const result = await getAllData({
    client,
    query: LIST_IR_EDITREASON_MASTER,
    dataPath: ["data", "listIRConfigurableMasters"],
    variables: { limit: 1000 },
    drillData: true
  });
  const reasonData = {
    listIRConfigurableMasters: {
      items: sortedList(result.items, ["configText"], ["asc"])
    }
  };

  return reasonData;
};

export const getAllMasterData = async ({ client, site }) => {
  return Promise.all([
    equipmentCategoryMappings(client),
    clusterCategoryMappings(client),
    getGroupMaster(client),
    getResponsibleMaster(client),
    getTagsMaster(client),
    getEquipmentAdministratorMaster(client),
    getLinkedInstance(client),
    getSops(client)
  ]).then((response) => {
    const equipmentCategoryMappingConfigurations = response[0];
    const clusterCategoryMappingConfigurations = response[1];
    const groupMasters = response[2];
    const filterLookups = response[3];
    const tagsMasters = response[4];
    const equipmentAdministrators = response[5];
    const linkedInstanceMasters = response[6];
    const sopMasters = response[7];
    return {
      listIRCategoryMappingConfigurations: {
        items: equipmentCategoryMappingConfigurations.items
      },
      listIRManufacturerMasters: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "manufacturer")
      },
      listIRVariant: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "variant")
      },
      listIRGroupMasters: {
        items: sortedList(groupMasters.items, ["value"])
      },
      listIRSOPMasters: {
        items: sortedList(sopMasters.items, ["value"])
      },
      listIRequipmentModelConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIRclusterModelConfigurations: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIREquipmentCategoryConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRClusterCategoryMappings: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRFilterLookups: {
        items: sortedList(filterLookups.items, ["filterValue"])
      },
      listIRTags: {
        items: sortedList(tagsMasters.items, ["filterValue"])
      },
      listIREquipmentAdministrators: {
        items: sortedList(equipmentAdministrators?.items, ["filterValue"])
      },
      listLinkedInstanceMasters: {
        items: sortedList(linkedInstanceMasters.items, ["linkedInstanceDisplay"])
      }
    };
  });
};

export const getUserDisplayColumn = async ({ client, userID }) => {
  const { items: listUserDisplays } = await getAllData({
    client,
    query: LIST_IR_DISPLAY_COLUMNS,
    variables: { userID },
    dataPath: ["data", "listUserDisplays"]
  });

  return listUserDisplays;
};

export const getSystemDisplayColumn = async ({ client }) => {
  const { items: listSystemDisplays } = await getAllData({
    client,
    query: LIST_IR_SYSTEM_DISPLAY_COLUMNS,
    dataPath: ["data", "listSystemDisplays"]
  });

  let systemDisplay = {
    ...listSystemDisplays[0],
    displayDefinition: JSON.parse(listSystemDisplays[0]?.displayDefinition)
  };
  let hiddenColumns = allTableColumn()?.filter((columnObj) => {
    const obj = find(systemDisplay?.displayDefinition?.showColumns, {
      key: columnObj?.key
    });
    return !obj;
  });

  systemDisplay = {
    ...systemDisplay,
    displayDefinition: JSON.stringify({
      ...systemDisplay?.displayDefinition,
      hideColumns: hiddenColumns
    })
  };

  return [systemDisplay];
};

const LoadInstrumentInfo = ({
  children,
  client,
  loadUserInfo,
  loadActionStatus,
  loadStepFormList,
  updateInstrumentFilter,
  updateBuildingOnSite,
  updateFloorOnSite,
  updateRoomOnSite,
  loadBuildingData,
  loadFloorData,
  loadRoomData,
  loadfilter,
  loadReasonData,
  loadLastDisplayColumn,
  updateUserDisplayColumns,
  updateSystemDisplayColumn,
  setIsInboxActive,
  setInboxCount
}) => {
  const redirectToTableau = localStorage.getItem("redirectToTableau");
  const history = useHistory();
  if (redirectToTableau === "true") history.push("/tableau-equipments");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const [userObject, setUserObject] = useState({});
  const { InventoryId } = useParams();

  const onCreateCallback = (subscriptionData) => {
    if (userObject.email === subscriptionData?.data?.onCreateIRInbox?.emailId) {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: "Export is in progress."
      });
    }
  };
  const onUpdateCallback = async (subscriptionData) => {
    const itemData = subscriptionData?.data?.onUpdateIRInbox;
    if (userObject.email === itemData?.emailId && itemData?.status === "COMPLETED") {
      const variables = {
        limit: 1000,
        emailId: userObject?.email,
        filter: {
          status: { eq: "COMPLETED" },
          emailId: { eq: userObject?.email }
        }
      };
      const { items } = await getAllData({
        client,
        query: LIST_IR_INBOXES,
        variables,
        dataPath: ["data", "listIRInboxs"]
      });

      setInboxCount(items.length);
      setIsInboxActive(true);
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: "New export is ready to download."
      });
    }
  };

  //let s3VersionIdArray = useSelector((state) => state?.user?.s3VersionIdArray);
  const onUpdateStatusCallback = (subscriptionData) => {
    const itemData = subscriptionData?.data?.onUpdateIRUploadStatus;

    if (userObject.email === itemData?.email) {
      if (
        itemData?.status === IR_FILE_UPLOAD_STATUS[1].statusKey ||
        itemData?.status === IR_FILE_UPLOAD_STATUS[2].statusKey
      ) {
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text:
            itemData?.status === IR_FILE_UPLOAD_STATUS[1].statusKey ? "CSV import started" : "CSV successfully uploaded"
        });
      } else if (itemData?.status === IR_FILE_UPLOAD_STATUS[3].statusKey) {
        switch (itemData?.errorMessage) {
          case "INVALID_FILE":
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "Invalid file format"
            });

            break;
          case "??":
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "File could not be loaded"
            });

            break;
          case "IMPORT_FAILED":
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "CSV import failed"
            });

            break;
          case "IMPORT_PARTIALLYFAILED":
            Notify({
              type: "alert",
              icon: "caution",
              appName: "",
              text: "Import of some entries failed"
            });

            break;

          default:
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "File could not be loaded"
            });
            break;
        }
      }
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { email, ...syncFields } = await checkRoleAndGetUserEmail();
        //TODO: uncomment the below line and comment the above to be tested in eu-west-1
        // const email = "ready4s+admin+dev+eu@protonmail.com";

        const { user } = await getUserDetails({
          client,
          email,
          updateInstrumentFilter,
          loadfilter,
          ...syncFields
        });

        setUserObject(user);

        //TODO: uncomment the below line and comment the above to be tested in eu-west-1
        // const user = {
        //   email: "ready4s+admin+dev+eu@protonmail.com",
        //   familyName: "admin",
        //   givenName: "dev",
        //   id: "e1c62a6d-0e2a-4271-9bbf-0b2cae8b2c5d",
        //   lastFilter: {},
        //   name: "ready4s+admin+dev+eu",
        //   phone: "+41616875923",
        //   site: "Rotkreuz",
        //   status: null,
        //   user: "900c7e94-2b18-455b-b501-056fafb6c9f3"
        // };
        // let UserDisplayColumns = await getDisplayColumn({
        //   client,
        //   query: LIST_SITES,
        //   dataPath: ["data", "listSites"]
        // });

        const userID = user.id;
        const listUserDisplays = await getUserDisplayColumn({ client, userID });
        updateUserDisplayColumns(listUserDisplays);

        const listSystemDisplays = await getSystemDisplayColumn({
          client
        });
        updateSystemDisplayColumn(listSystemDisplays);

        let { items: sites } = await getAllData({
          client,
          query: LIST_SITES,
          dataPath: ["data", "listSites"]
        });

        const { items: groupList } = await getAllData({
          client,
          query: lIST_IR_GROUP_ADMIN_MAPPINGS,
          dataPath: ["data", "listIRGroupAdminMappings"]
        });
        user.groups = groupList.filter((item) => {
          return find(item.userIds, { userId: user?.user });
        });
        //TODO: uncomment the below line and comment the above to be tested in eu-west-1
        // let sites = [{ siteName: "Rotkreuz" }, { siteName: "Pleasanton" }];
        sites =
          sites?.length === 0
            ? [
                {
                  siteName: DEFAULT_SITE_NAME,
                  siteTimezone: DEFAULT_SITE_TIMEZONE
                }
              ]
            : sites;
        let key_mismatch = false;
        if (user?.lastDisplayColumns !== null && typeof user?.lastDisplayColumns === "object") {
          user.lastDisplayColumns.displayDefinition =
            typeof user.lastDisplayColumns.displayDefinition === "string"
              ? JSON.parse(user.lastDisplayColumns.displayDefinition)
              : user.lastDisplayColumns.displayDefinition;
          var filter_columns = [
            ...user?.lastDisplayColumns?.displayDefinition?.showColumns,
            ...user?.lastDisplayColumns?.displayDefinition?.hideColumns
          ];

          allTableColumn().forEach((column) => {
            const field = find(filter_columns, { key: column.key });

            if (!field) {
              key_mismatch = true;
            }
          });
        }
        if (
          user?.lastDisplayColumns?.displayDefinition?.hideColumns &&
          user?.lastDisplayColumns?.displayDefinition?.showColumns
        ) {
          var showFilterData = await getFilterConstData(
            user.lastDisplayColumns?.displayDefinition?.showColumns,
            "show"
          );
          var hideFilterData = await getFilterConstData(
            user.lastDisplayColumns?.displayDefinition?.hideColumns,
            "hide"
          );
        }

        user.lastDisplayColumns = {
          displayName: user?.lastDisplayColumns?.displayName
            ? user?.lastDisplayColumns?.displayName
            : DEFAULT_COLUMNS?.displayName,
          displayDefinition: {
            showColumns:
              !user.lastDisplayColumns?.displayDefinition?.showColumns || key_mismatch
                ? allTableColumn().filter((item) => item.show)
                : showFilterData,
            hideColumns:
              !user.lastDisplayColumns?.displayDefinition?.hideColumns || key_mismatch
                ? allTableColumn().filter((item) => !item.show)
                : hideFilterData
          }
        };

        const { myLabFilter } = await getLPUserDetailsUserFilter({
          client
        });
        let lastFavFilter = user.lastFilter?.lastFavFilter ? user.lastFilter?.lastFavFilter : DEFAULT_FILTER;

        if (
          myLabFilter &&
          myLabFilter?.filterDefinition?.inventoryIds &&
          myLabFilter?.filterDefinition?.inventoryIds?.length > 0 &&
          lastFavFilter?.filterName === MY_LAB
        ) {
          lastFavFilter = myLabFilter;
        } else if (lastFavFilter?.filterName === MY_LAB) {
          if (typeof InventoryId !== "undefined") {
            lastFavFilter = DEFAULT_FILTER;
          }
        }

        let lastPageSize = user?.lastPageSize;
        if (typeof lastPageSize === "string" && lastPageSize !== "") {
          lastPageSize = JSON.parse(lastPageSize);
        }
        lastPageSize =
          lastPageSize === ""
            ? DEFAULT_IR_PAGE_SIZE
            : lastPageSize?.ir_grid === 5
            ? { ...lastPageSize, ir_grid: 10 }
            : lastPageSize;

        loadLastDisplayColumn(user.lastDisplayColumns);

        const { userFilters } = await getUserFilter({
          client,
          email,
          query_user_filter: LIST_DIGITAL_LAB_INSTRUMENT_USER_FILTER
        });

        loadUserInfo({
          email,
          sites,
          ...user,
          userFilters,
          lastFavFilter,
          lastPageSize,
          myLabFilter
        });

        const reasons = await getReasons(client);

        loadReasonData(reasons);
        // get Master datas other than building
        const masterData = await getAllMasterData({
          client,
          site: user?.site
        });
        loadStepFormList(omitDeep(masterData, "__typename"));
        const actionStatus = await checkRoleInstrumentRepo(user?.groups?.length > 0, user?.groups?.length > 0);

        loadActionStatus(actionStatus);

        const inboxVariables = {
          limit: 1000,
          emailId: user?.email,
          filter: { status: { eq: "COMPLETED" }, emailId: { eq: user?.email } }
        };
        const { items } = await getAllData({
          client,
          query: LIST_IR_INBOXES,
          variables: inboxVariables,
          dataPath: ["data", "listIRInboxs"]
        });

        setInboxCount(items.length);
      } catch (err) {
        console.warn(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserInfo, loadStepFormList, client, updateInstrumentFilter, retry, loadfilter]);
  if (loading)
    return (
      <FullScreenCentered>
        <CircularProgress data-testid="loading" size={80} />
      </FullScreenCentered>
    );
  if (error !== null)
    return (
      <FullScreenCentered>
        <>
          <Typography variant="h6" data-testid="message-error" gutterBottom>
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </Typography>
          {error?.name !== "SiteError" && (
            <OwcButton variant="error" onClick={() => setRetry(retry + 1)} data-testid="try-again-button">
              Try again
            </OwcButton>
          )}
          <OwcButton
            data-testid="signout-button"
            variant="secondary"
            style={{ marginTop: ".7rem" }}
            onClick={() => signOut()}
          >
            Sign out
          </OwcButton>
        </>
      </FullScreenCentered>
    );
  return (
    <>
      {children}
      <GenericSubscription
        query={LIST_IR_INBOXES}
        subscription={ON_CREATE_IR_INBOX}
        onUpdateCallback={onCreateCallback}
        variables={{ limit: 1 }}
      />
      <GenericSubscription
        query={LIST_IR_INBOXES}
        subscription={ON_UPDATE_IR_INBOX}
        onUpdateCallback={onUpdateCallback}
        variables={{ limit: 1 }}
      />

      <GenericSubscription
        query={LIST_IR_UPLOAD_STATUS_MASTER}
        subscription={ON_UPDATE_IR_UPLOAD_STATUS}
        onUpdateCallback={onUpdateStatusCallback}
        variables={{ limit: 1 }}
      />
    </>
  );
};

export default compose(
  connect(null, {
    loadUserInfo: loadUserInfoAction,
    loadStepFormList: loadStepFormListAction,
    updateInstrumentFilter: updateInstrumentFilterAction,
    updateBuildingOnSite: updateBuildingOnSiteAction,
    updateFloorOnSite: updateFloorOnSiteAction,
    updateRoomOnSite: updateRoomOnSiteAction,
    loadBuildingData: loadBuildingDataAction,
    loadFloorData: loadFloorDataAction,
    loadRoomData: loadRoomDataAction,
    loadfilter: loadLastFilter,
    loadActionStatus: loadActionStatusAction,
    loadReasonData: loadReasonDataAction,
    loadLastDisplayColumn: updateLastDisplayColumn,
    updateUserDisplayColumns: updateUserDisplayColumnsAction,
    updateSystemDisplayColumn: updateSystemDisplayColumnAction
  }),
  withApollo
)(LoadInstrumentInfo);
